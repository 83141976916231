<template>
  <div >
    <el-row :gutter="10" style="margin-top:-10px">
      <el-col :span="18">
        <el-carousel class="imgbox" height="330px" >
            <el-carousel-item ><img src="~pub/img/11.png" alt="" class="img"></el-carousel-item>
            <!-- <el-carousel-item ><img src="~pub/img/2.jpg" alt=""></el-carousel-item>
            <el-carousel-item ><img src="~pub/img/3.jpg" alt=""></el-carousel-item> -->
        </el-carousel>
        <el-card class="box-card boxcard" style="margin-top:10px">
            <div slot="header" class="clearfix">
                <span>任务列表</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="tolist()">查看更多</el-button>
            </div>
            <div v-for="item in list" :key="item.taskId"  class="box-card int" @click="management(item.taskId)">
               <div>  <span style="font-size:16px">{{item.taskName}}</span>
                <span  class="mini">{{item.taskStateName}}</span>
                </div>
                <div class="two">
                    起始日期 {{item.taskStartTime}} ~ {{item.taskEndTime}}
                    <span class="listspan">结算周期 {{item.settlementCycleName}}</span>
                    <span class="listspan">结算标准  {{item.price}} {{item.settlementStandardName}}</span>
                    <!-- <span class="listspan">所属行业 {{item.industryName}}</span> -->
                </div>
                <div style="margin-top:10px">
                    {{item.taskDesc}}
                </div>
                <el-divider></el-divider>
            </div>
            </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card right">
            <div slot="header" class="clearfix">
                <span>待办事项</span>
            </div>
            <a type="texta" href="https://www.ytzbricks.com/download/%E9%9B%B2%E9%80%94%E5%BF%97%E6%B3%A8%E5%86%8C%E6%B5%81%E7%A8%8B.pdf">点击下载《雲途志产品操作手册》</a>
            <br>
            <div style="margin-top:20px">
                 <a href="https://www.ytzbricks.com/download/%E9%9B%B2%E9%80%94%E5%BF%97%E5%B0%8F%E7%A8%8B%E5%BA%8F%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf" >点击下载《雲途志小程序操作手册》</a>
            </div>
           
            <!-- <el-button  type="text" @click="down()">点击下载《雲途志产品操作手册》</el-button> -->
            <!-- <div class="boxtext">
                <p>1笔</p>
                <p>待充值</p>
            </div>
            <div class="boxtext">
                <p>1笔</p>
                <p>待充值</p>
            </div>
            <div class="boxtext">
                <p>1笔</p>
                <p>待充值</p>
            </div>
            <div class="boxtext" style=" margin-top: 20px;">
                <p>1笔</p>
                <p>待充值</p>
            </div> -->
        </el-card>
         <el-card class="box-card" style="margin-top:10px;height: 360px;">
            <div slot="header" class="clearfix">
                <span>快捷入口</span>
            </div>
            <!-- <div class="boxtext"  v-for="o in 9" :key="o">
                <p><i class="el-icon-picture"></i></p>
                <router-link to='/testDemo'>快速开票</router-link>
            </div> -->
            <div style="margin-top:-30px">
             <div class="boxtext"  @click="$router.push('task-release')">
                <p><i class="iconfont icon-xinjianrenwu color" style="font-size:40px"></i></p>
                <p>新建任务</p>
             </div>
             <div class="boxtext" @click="$router.push('capital-recharge')">
                <p><i class="iconfont icon-chongzhi color"></i></p>
                 <p>快速充值</p>
                <router-link to='/testDemo'></router-link>
            </div>
             <div class="boxtext" @click="$router.push('capital-record')">
                <p><i class="iconfont icon-jiaoyijilu color"></i></p>
                 <p>交易记录</p>
                <router-link to='/testDemo'></router-link>
            </div>
            <div class="boxtext" @click="$router.push('task-settlement')">
                <p><i class="iconfont icon-jiesuanjilu color"></i></p>
                 <p>结算记录</p>
                <router-link to='/testDemo'></router-link>
            </div>
            <div class="boxtext" @click="$router.push('capital-capital')">
                <p><i class="iconfont  icon-yue color"></i></p>
                 <p>余额查询</p>
                <router-link to='/testDemo'></router-link>
            </div>
            </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {merchantTaskPageList } from  "@/api/task";
export default {
  name: "",
  components: {},
  data() {
    return {
        list:[],
        pageSize: 10,
        totalCount: 0,//数据长度
        currPage: 1,
    };
  },
   created(){
    this.getDataList()
  },
  methods:{
  getDataList(a){
    if (a) {  //判断是不是搜索
          this.currPage=1 
          this.pageSize=10
        }
     merchantTaskPageList({
       "taskState":"10031",
        "taskName": "",
        "current": this.currPage,
        "size": 3,
     }).then((data=>{
        if (data.code==200) {
            this.list=data.data.records
            this.totalCount=data.data.total
       }
     }))
  },
   tolist(){
        this.$router.push({path:'/task-task'});
      },
          // 任务管理
      management(a){
        this.$router.push({path:'/task/management',query:{taskId:a}});
      },
  }
};
</script>
<style scoped>
  .el-col {
    border-radius:4px;
  }
  .imgbox img{
    z-index:-99999;
    width: 1300px;
    background: darkcyan;
    background-size: cover;
  }
  .right{
   height: 200px;
  }
  .mini{
      display: inline-block;
      height: 20px;
      line-height: 20px;
      text-align: center;
      width:60px;
      border: 1px solid #9e99b9;
      border-radius: 25px ;
      margin-left:14px;
      transform: translateY(-2px);
      font-size: 10px;
  }
  .boxtext{
      width: 30%;
      height: 100px;
      float: left;
      margin-top: 30px;
      /* background: darkcyan; */
      text-align: center;
       /* margin-top: 10px; */
       font-size:14px;
       cursor: pointer;
  }
  .boxcard{
      /* height: 600px; */
  }
  .listspan{
      margin-left: 30px;
  }
  .two{
      font-size: 13px;
  }
  .clearfix{
    font-size:16px
  }
  .color{
      color: #92dfd6;
      font-size: 36px;
      font-weight: 400;
  }
  .two{
      font-size: 13px;
      margin-top: 10px;
  }
.right a{
    color: #9e99b9;
    text-decoration: none;
    font-size: 14px;
}
.int{
   cursor: pointer;
}
</style>
